// Feedback.jsx

import React, { useState } from 'react';
import styles from './Feedback.module.scss';

const Feedback = () => {
  const [reviewText, setReviewText] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = event => {
    event.preventDefault();
    // Добавьте здесь логику отправки отзыва, например, через API

    // После успешной отправки отзыва показываем модальное окно
    setShowModal(true);

    // Сбросить текст отзыва
    setReviewText('');
  };
  const handleClick = () => {
    window.open('https://t.me/nick', '_blank');
  };
  return (
    <div className={styles.feedbackContainer}>
      <h1>Отзывы</h1>
      <p>
        Чтобы оставить отзыв нужно совершить встречу и связаться с{' '}
        <span onClick={handleClick} className={styles.tg}>
          менеджером
        </span>
      </p>
      <div className={styles.boxFeed}>
        <h3>Макс</h3>
        <p>Модель: Дина</p>
        <p>
          Только что побывал в гостях у этой красотки. Принимает в студии в
          новом жк с живописным видом из окна. Сама девушка худенькая как
          пушинка со спортивной фигурой, личико очень красивое на мой вкус.
          Отличный минет, по классике все узенькое, в сексе активнаяя
        </p>
      </div>
      <div className={styles.boxFeed}>
        <h3>Андрей</h3>
        <p>Модель: Николь</p>
        <p>
          Самая лучшая и неповторимая на свете! Николь идеальна во всём, как
          внутри, так и внешне?Влюбился в неё сразу, абсолютно уникальное
          создание и творение❤️Всё на высшем уровне - трахал бы всю жизнь! Ни к
          кому никогда ходить не буду, только к ней??Я постоянны
        </p>
      </div>
      <div className={styles.boxFeed}>
        <h3>Артур</h3>
        <p>Модель: Юля</p>
        <p>
          Юлия самая топовая, был несколько дней назад, с порога располагает к
          себе и сразу начинает приставать, очень любит сосать, прям не
          остановить) Залил ее личико полностью. При этом в разговоре очень
          милая и приятная. Спасибо за эмоции, ты супер, загляну еще
        </p>
      </div>
      <div className={styles.boxFeed}>
        <h3>Евгений</h3>
        <p>Модель: Даша</p>
        <p>
          Отличная девушка, божественная, сочная и упругая фигура. Фото
          соответствует. По процессу - выше всяческих похвал. От души всю её
          вылизал, за два часа кончил два раза. Дома чисто, всё необходимое
          предлагает. Однозначно рекомендую, обязательно вернусь ещё.
        </p>
      </div>
      <div className={styles.boxFeed}>
        <h3>Дмитрий</h3>
        <p>Модель: Николь</p>
        <p>
          Красивая, миниатюрная девушка. Николь принемает в шикарных
          аппартаментах. Выглядит как на фото. В процессе исполнительна и
          активна. Спасибо милая!
        </p>
      </div>
      <div className={styles.boxFeed}>
        <h3>Сергей</h3>
        <p>Модель: Даша</p>
        <p>
          Встречался с девушкой на имя Даша. У неё еще есть горячий канал «Жизнь
          модели». Провели шикарную ночь, сказочный горловой и глубокий анал??
        </p>
      </div>
      <div className={styles.boxFeed}>
        <h3>Анатолий</h3>
        <p>Модель: Анастасия</p>
        <p>
          Красивая девчонка, веселая и темпераментная. Вся полностью отдается
          работе :)
        </p>
      </div>
      <div className={styles.boxFeed}>
        <h3>Михаил</h3>
        <p>Модель: Вика</p>
        <p>
          такой бриллиант все берегут для себя ?. Можно написать целую поэму
          насколько она прекрасна!! Внешность, общение, сервис на высоте!! Ну а
          по поводу интимной части - скажу одно - Космос!!! От секса в таких
          позах получаешь одновременно и эстетической оргазм!
        </p>
      </div>
      <div className={styles.boxFeed}>
        <h3>Аноним</h3>
        <p>Модель: Кира</p>
        <p>
          Хорошая девочка, как на фото, минет хорошо по началу прям очень
          хорошо, стонет хорошо, класс. секс так себе, но вот попка огонь прям
          не боится размера, но на продление не захотела? в целом дама хороша,
          обязательно ещё приеду.
        </p>
      </div>
      <div className={styles.boxFeed}>
        <h3>Димка</h3>
        <p>Модель: Алина</p>
        <p>
          Был у барышни, фигура хорошая. Красивая, грудь огонь! Все понравилось.
          Квартира в новом ЖК. Рекомендую.
        </p>
      </div>
      <div className={styles.boxFeed}>
        <h3>Кирилл</h3>
        <p>Модель: Аня</p>
        <p>
          Фото соответствует, все супер. Очень сексуальная малышка! Возбуждает
          одним видом, встреча было на высшем уровне! Я почти влюбился. Мбр на
          5+
        </p>
      </div>
      <div className={styles.boxFeed}>
        <h3>Марк</h3>
        <p>Модель: Лиза</p>
        <p>
          Встречался с данной девушкой уже не один раз, время провождение с ней
          максимально приятное МБР - потрясающий, поцелуи тоже Девушка следит за
          собой и находится рядом с ней одно удовольствие
        </p>
      </div>
      <div className={styles.boxFeed}>
        <h3>Владислав</h3>
        <p>Модель: Вилена</p>
        <p>
          Отличная девчонка, фигурка обалденная, МБР + горловой, а как целуется,
          все, что попросил ни в чем не отказала. Эстетика молодого тела радует
          глаз. Квартира в новом доме, все чисто и уютно.
        </p>
      </div>
      <div className={styles.boxFeed}>
        <h3>Владислав</h3>
        <p>Модель: Василиса</p>
        <p>
          Хорошая девочка. Приятно провел время. Стройная, красивая. Минет и
          секс на хорошем уровне.
        </p>
      </div>
      <div className={styles.boxFeed}>
        <h3>Владик</h3>
        <p>Модель: Юляша</p>
        <p>
          Мой выбор постоянен.Очень симпатичная, нежная, светленькая.Мне
          напоминает мою первую любовь из детства.По процессу все прекрасно,
          делает все что указано в анкете.Вообщем отличный человек.Для меня
          самое прекрасное, это наблюдать ее утром у себя в постеле,
        </p>
      </div>
      <div className={styles.boxFeed}>
        <h3>Витя</h3>
        <p>Модель: Юляшка</p>
        <p>
          Девочка просто огонь. Удовлетворила меня во всём,очень красивая и
          очень опытная красотка. Всё прошло на высшем уровне.
        </p>
      </div>
      <div className={styles.boxFeed}>
        <h3>Виктор</h3>
        <p>Модель: Анастасия</p>
        <p>
          Невероятное агентство с качественным сервисом! Девушка чувствовала
          настроение и была очень общительна. Все было идеально, спасибо за
          замечательный вечер!
        </p>
      </div>
      <div className={styles.boxFeed}>
        <h3>Данил</h3>
        <p>Модель: Вика</p>
        <p>
          Переслано от Егор Хочу отметить, что в этом агенстве - профессионализм
          на высшем уровне! Все условия были соблюдены, девушка была
          очаровательна и компания приятная. Обязательно порекомендую знакомым!
        </p>
      </div>
      <div className={styles.boxFeed}>
        <h3>Константин</h3>
        <p>Модель: Лейла</p>
        <p>
          Отличное обслуживание, девушка была очаровательной и внимательной.
          Остался приятный послевкусие от встречи, с удовольствием обращусь еще
          раз!
        </p>
      </div>

      <div>
        <form onSubmit={handleSubmit}>
          <div className={styles.inputName}>
            <h4> Имя</h4>

            <input type="text" />
          </div>
          <div className={styles.inputName}>
            <h4> Модель</h4>

            <input type="text" />
          </div>

          <textarea
            value={reviewText}
            onChange={e => setReviewText(e.target.value)}
            placeholder="Напишите ваш отзыв здесь..."
            rows={4}
            cols={50}
            required
            className={styles.text}
          />
          <br />
          <button type="submit" className={styles.link}>
            Отправить отзыв
          </button>
        </form>

        {showModal && (
          <div className={styles.modalBackground}>
            <div className={styles.modalContent}>
              <p>
                Ваш отзыв появится в течение 24 часов после проверки модератора.
              </p>
              <button
                onClick={() => setShowModal(false)}
                className={styles.link}
              >
                Закрыть
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Feedback;
