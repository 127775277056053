// src/city.js
const cities = [
  'Абакан', 'Абинск', 'Агата', 'Агинское (Забайк.)', 'Адлер', 'Адыгейск', 'Азов (Рост.)',
  'Алагир', 'Алапаевск', 'Алдан', 'Александров', 'Александров Гай', 'Александровск',
  'Александровск-Сахалинский', 'Алексин', 'Алушта', 'Алупка', 'Амдерма', 'Амурск',
  'Анадырь', 'Анапа', 'Ангарск', 'Андреаполь', 'Анива', 'Апатиты', 'Апрелевка',
  'Апшеронск', 'Аргаяш', 'Ардон', 'Арзамас', 'Армавир', 'Арсеньев', 'Артем',
  'Архангельск', 'Архыз', 'Аршан (Бурят.)', 'Асбест', 'Асино', 'Астрахань', 'Ахтубинск',
  'Ачинск', 'Ачхой Мартан', 'Аша', 'Бавлы', 'Байкальск', 'Баксан', 'Балашиха',
  'Балашов', 'Балтийск', 'Баргузин', 'Барнаул', 'Батайск', 'Белгород', 'Белогорск (Амур.)',
  'Белокуриха', 'Беломорск', 'Белорецк', 'Белореченск', 'Белоярский', 'Белый Яр (Томск.)',
  'Березники', 'Беслан', 'Бийск', 'Билибино', 'Биробиджан', 'Бирск', 'Благовещенск (Амур.)',
  'Богучар', 'Бодайбо', 'Бологое', 'Бомнак', 'Борзя', 'Боровск', 'Братск', 'Бреды',
  'Бронницы', 'Брянск', 'Бугульма', 'Бугуруслан', 'Буденновск', 'Бузулук', 'Буйнакск',
  'Быково (метеост.)', 'Валаам', 'Валдай', 'Ведено', 'Великие Луки', 'Великий Устюг',
  'Вендинга', 'Верещагино (Перм.)', 'Верхнее Пенжино', 'Верхний Баскунчак',
  'Верхний Тагил', 'Верхний Уфалей', 'Верхотурье', 'Верхоянск', 'Видное', 'Вилюйск',
  'Витим', 'Владивосток', 'Владикавказ', 'Владимир', 'Внуково (метеост.)', 'Волгоград',
  'Волгодонск', 'Вологда', 'Волоколамск', 'Волхов', 'Воркута', 'Воронеж', 'Воскресенск',
  'Воткинск', 'Всеволожск', 'Вуктыл', 'Выборг', 'Вытегра', 'Вязьма', 'Гаврилов-Ям',
  'Гагарин', 'Галич', 'Гатчина', 'Гдов', 'Геленджик', 'Глазов', 'Голицыно',
  'Горно-Алтайск', 'Городовиковск', 'Горячий Ключ', 'Горячинск', 'Гремячинск (Бурят.)',
  'Гремячинск (Перм.)', 'Грозный', 'Губаха', 'Губкин', 'Губкинский', 'Гудермес', 'Гурзуф',
  'Гусь-Хрустальный', 'Дагомыс', 'Далматово', 'Данков', 'Двинской', 'Дербент', 'Джейрах',
  'Джубга', 'Дзержинск', 'Дивногорск', 'Диксон', 'Дмитров', 'Дно', 'Добрянка', 'Долинск',
  'Домбай', 'Домодедово', 'Дубна', 'Дудинка', 'Егорьевск', 'Ейск', 'Екатеринбург',
  'Елабуга', 'Елатьма', 'Елец', 'Ельня', 'Енисейск', 'Ербогачен', 'Ершов', 'Ессентуки',
  'Железногорск (Курск.)', 'Жиганск', 'Жигулевск', 'Жуковский', 'Забайкальск',
  'Заводоуковск', 'Завьялиха', 'Зарайск', 'Звенигород', 'Зеленогорск (Ленин.)',
  'Зеленоград', 'Златоуст', 'Змеиногорск', 'Иваново', 'Ивдель', 'Игарка', 'Игнашино',
  'Ижевск', 'Избербаш', 'Инта', 'Ирбит', 'Иркутск', 'Истра', 'Ишим', 'Йошкар-Ола',
  'Кабанск', 'Кажим', 'Казань', 'Калач', 'Калач-на-Дону', 'Калачинск', 'Калевала',
  'Калининград', 'Калуга', 'Калязин', 'Каменномостский', 'Каменск-Уральский',
  'Каменск-Шахтинский', 'Камень-на-Оби', 'Камышин', 'Камышлов', 'Кандалакша', 'Каневская',
  'Канск', 'Карабудахкент', 'Карабулак', 'Карачаевск', 'Каргасок', 'Каргополь', 'Карпинск',
  'Карталы', 'Касимов', 'Каспийск', 'Катав-Ивановск', 'Катайск', 'Качканар', 'Кашира',
  'Кашхатау', 'Кедровый', 'Кежма', 'Керчь', 'Кемерово', 'Кетченеры', 'Кижи', 'Кизел',
  'Кизилюрт', 'Кизляр', 'Кимры', 'Кингисепп', 'Кинешма', 'Киренск', 'Киржач', 'Кириши',
  'Киров (Вятка)', 'Кирово-Чепецк', 'Кировск (Мурм.)', 'Кировск (Ленин.)', 'Кисловодск',
  'Клин', 'Ковров', 'Когалым', 'Коломна', 'Колпашево', 'Комсомольск-на-Амуре', 'Кондопога',
  'Королев', 'Корсаков', 'Костомукша', 'Кострома', 'Котельнич', 'Котлас', 'Кош-Агач',
  'Красная Поляна', 'Красновишерск', 'Красногорск (Моск.)', 'Краснодар', 'Краснокамск',
  'Красноселькуп', 'Краснотурьинск', 'Красноуральск', 'Красноуфимск', 'Красноярск',
  'Кропоткин (Краснод.)', 'Крымск', 'Кудымкар', 'Кузнецк', 'Кулу', 'Кулунда', 'Кунгур',
  'Курган', 'Курганинск', 'Курильск', 'Курск', 'Куртамыш', 'Курумкан', 'Курчатов',
  'Кущевская', 'Кызыл', 'Кырен', 'Кыштым', 'Кяхта', 'Лабинск', 'Лабытнанги', 'Лагань',
  'Лазаревское', 'Лесной (Сверд.)', 'Липецк', 'Листвянка (Иркут.)', 'Лодейное Поле',
  'Лотошино', 'Луга', 'Луховицы', 'Лысьва', 'Льгов', 'Любань', 'Люберцы', 'Лянтор',
  'Магадан', 'Магас', 'Магнитогорск', 'Майкоп', 'Макаров', 'Макушино', 'Малая Вишера',
  'Малгобек', 'Малоярославец', 'Мама', 'Мамоново', 'Мантурово', 'Мариинск', 'Мариинский Посад',
  'Маркс', 'Махачкала', 'Мглин', 'Мегион', 'Медвежьегорск', 'Мезень', 'Мелеуз', 'Менделеевск',
  'Мензелинск', 'Мещовск', 'Миасс', 'Минеральные Воды', 'Мирный (Якут.)', 'Мирный (Арханг.)',
  'Михайловка', 'Михайловск', 'Мичуринск', 'Могоча', 'Моздок', 'Мончегорск', 'Морки', 'Морозовск',
  'Моршанск', 'Мурманск', 'Муром', 'Мценск', 'Мыски', 'Мышкин', 'Набережные Челны', 'Навашино',
  'Надым', 'Назрань', 'Нальчик', 'Наро-Фоминск', 'Нарьян-Мар', 'Нахабино', 'Находка', 'Невельск',
  'Невинномысск', 'Невьянск', 'Нелидово', 'Немчиновка', 'Нерюнгри', 'Нефтегорск', 'Нефтекамск',
  'Нефтеюганск', 'Нижневартовск', 'Нижнекамск', 'Нижнеудинск', 'Нижние Серги', 'Нижний Новгород',
  'Нижний Тагил', 'Николаевск-на-Амуре', 'Новая Ладога', 'Новгород', 'Новокузнецк', 'Новомосковск',
  'Новосибирск', 'Новотроицк', 'Новоуральск', 'Новочебоксарск', 'Новочеркасск', 'Новый Оскол',
  'Новый Уренгой', 'Ногинск', 'Норильск', 'Ноябрьск', 'Нягань', 'Облучье', 'Обнинск', 'Обь',
  'Одинцово', 'Озерск (Челяб.)', 'Октябрьское', 'Октябрьский', 'Омск', 'Опочка', 'Оренбург',
  'Орехово-Зуево', 'Орловский', 'Орск', 'Осинники', 'Осташков', 'Остров', 'Отрадная', 'Оха',
  'Охотск', 'Очер', 'Павлово', 'Павловск (Ворон.)', 'Палана', 'Партизанск', 'Певек', 'Пенза',
  'Первомайское (метеост.)', 'Переславль-Залесский', 'Пермь', 'Петрозаводск', 'Петропавловск-Камчатский',
  'Печора', 'Пионерский', 'Питкяранта', 'Плавск', 'Пластун', 'Плесецк', 'Поворино', 'Подгоренский',
  'Подольск', 'Покровка', 'Полевской', 'Полуночное', 'Поронайск', 'Похвистнево', 'Правдинск',
  'Пржевальское', 'Пролетарск', 'Протвино', 'Псков', 'Пугачев', 'Пудож', 'Пустошка', 'Пучеж',
  'Пушкин', 'Пушкино', 'Пущино', 'Пыталово', 'Пыть-Ях', 'Пятигорск', 'Радужный', 'Раменское',
  'Рассказово', 'Ревда', 'Реж', 'Репьевка', 'Реутов', 'Ржев', 'Ровеньки (метеост.)', 'Рогачево',
  'Родино', 'Рославль', 'Россошь', 'Ростов Великий', 'Ростов-на-Дону', 'Рубцовск', 'Руза',
  'Рыбинск', 'Рыбное', 'Рыльск', 'Ряжск', 'Рязань', 'Саки', 'Салаир', 'Салехард', 'Сальск',
  'Самара', 'Самбург', 'Сарапул', 'Саратов', 'Саров', 'Сафоново', 'Саяногорск',
  'Саянск', 'Северобайкальск', 'Северодвинск', 'Североморск', 'Североуральск', 'Севск', 'Сегежа',
  'Сельцо', 'Семикаракорск', 'Семилуки', 'Серафимович', 'Сергач', 'Сергиев Посад', 'Серов', 'Серпухов',
  'Сертолово', 'Сибай', 'Славгород', 'Славск', 'Славянка', 'Славянск-на-Кубани', 'Слюдянка', 'Смоленск',
  'Снежногорск', 'Снежинск', 'Соболево', 'Советск (Киров.)', 'Советская Гавань', 'Сокол', 'Соликамск',
  'Солнечногорск', 'Соль-Илецк', 'Сольвычегодск', 'Сорочинск', 'Сосновка (Вятка.)', 'Сосногорск',
  'Сочи', 'Спасск-Дальний', 'Среднеколымск', 'Среднеуральск', 'Ставрополь', 'Старая Русса', 'Старый Оскол',
  'Степанцминда', 'Стрежевой', 'Ступино', 'Суворов', 'Судогда', 'Суздаль', 'Сунтар', 'Сургут', 'Суровикино',
  'Сусуман', 'Сухиничи', 'Сухой Лог', 'Сызрань', 'Сыктывкар', 'Сысерть', 'Тавда', 'Тайга', 'Тайшет',
  'Талдом', 'Тамбов', 'Тара', 'Тарко-Сале', 'Татарск', 'Таштып', 'Тверь', 'Теберда', 'Тейково',
  'Темников', 'Темрюк', 'Терек', 'Тетюши', 'Тикси', 'Тихвин', 'Тихорецк', 'Тобольск', 'Тогучин',
  'Тольятти', 'Томмот', 'Томск', 'Топки', 'Торжок', 'Торопец', 'Тосно', 'Тотьма', 'Троицк (Челяб.)',
  'Трубчевск', 'Туапсе', 'Тула', 'Тулун', 'Туран', 'Турочак', 'Туруханск', 'Тутайев', 'Тында',
  'Тюкалинск', 'Тюмень', 'Ува', 'Уварово', 'Углич', 'Удачный', 'Удомля', 'Ужур', 'Улан-Удэ',
  'Ульяновск', 'Умет', 'Унеча', 'Урай', 'Урджар', 'Урус-Мартан', 'Урюпинск', 'Усинск', 'Усмань',
  'Усолье-Сибирское', 'Успенка', 'Уссурийск', 'Усть-Вымь', 'Усть-Джегута', 'Усть-Илимск', 'Усть-Каменогорск',
  'Усть-Кокса', 'Усть-Кулом', 'Усть-Кут', 'Усть-Ордынский', 'Уфа', 'Ухта', 'Учалы', 'Уэльс', 'Фокино (Брян.)',
  'Фролово', 'Хабаровск', 'Хадыженск', 'Ханты-Мансийск', 'Харабали', 'Харовск', 'Хасавюрт', 'Хатанга',
  'Хатанга (метеост.)', 'Хвалынск', 'Хилок', 'Химки', 'Холмогоры', 'Холмск', 'Хомутово', 'Хоринск',
  'Художников (метеост.)', 'Цимлянск', 'Цулад', 'Чадан', 'Чайковский', 'Чара', 'Чебаркуль', 'Чебоксары',
  'Чегдомын', 'Чекурдах', 'Чекурдах (метеост.)', 'Челябинск', 'Чекурдах (метеост.)', 'Чекурдах (метеост.)',
  'Чемал', 'Чепца', 'Черемисино', 'Черемушки', 'Черемхово', 'Черкесск', 'Чермоз', 'Черноголовка',
  'Черногорск', 'Чернушка', 'Черняховск', 'Чистополь', 'Чита', 'Чишмы', 'Чкаловский', 'Чугуевка',
  'Чукур-Даг', 'Чукурган', 'Чулым', 'Шагонар', 'Шадринск', 'Шали', 'Шарыпово', 'Шатура', 'Шахунья',
  'Шахты', 'Шацк', 'Шебалино', 'Шелехов', 'Шенкурск', 'Шилка', 'Шумерля', 'Щекино', 'Щелково',
  'Электросталь', 'Элиста', 'Энгельс', 'Эриванка', 'Эссойла', 'Югорск', 'Южа', 'Южно-Сахалинск',
  'Южноуральск', 'Юкаменский', 'Юкка', 'Юрга', 'Юрьевец', 'Юрюзань', 'Юхнов', 'Ядрин', 'Якутск',
  'Ялта', 'Янаул', 'Яранск', 'Яровое', 'Ярославль', 'Ярцево', 'Ясногорск', 'Ясный', 'Яхрома',
  'Альметьевск', 'Анапа', 'Грозный', 'Ейск', 'Ессентуки', 'Железноводск', 'Зеленокумск', 'Каспийск',
  'Кисловодск', 'Лермонтов', 'Минеральные Воды', 'Михайловск', 'Назрань', 'Пятигорск', 'Светлоград',
  'Советский', 'Черкесск', 'Элиста', 'Южно-Сухокумск', 'Адлер', 'Артемовский', 'Архыз', 'Астрахань',
  'Барнаул', 'Белокуриха', 'Бийск', 'Горно-Алтайск', 'Екатеринбург', 'Ижевск', 'Иркутск', 'Казань',
  'Кемерово', 'Краснодар', 'Красноярск', 'Курган', 'Кызыл', 'Лабинск', 'Магадан', 'Махачкала',
  'Мирный', 'Москва', 'Набережные Челны', 'Надым', 'Находка', 'Нижневартовск', 'Нижний Новгород',
  'Нижний Тагил', 'Новокузнецк', 'Новосибирск', 'Новый Уренгой', 'Ноябрьск', 'Омск', 'Оренбург',
  'Пермь', 'Петропавловск-Камчатский', 'Пыть-Ях', 'Ростов-на-Дону', 'Салехард', 'Самара', 'Санкт-Петербург',
  'Саратов', 'Сургут', 'Сыктывкар', 'Таганрог', 'Тамбов', 'Тверь', 'Томск', 'Тула', 'Тюмень',
  'Улан-Удэ', 'Ульяновск', 'Уфа', 'Хабаровск', 'Ханты-Мансийск', 'Чебоксары', 'Челябинск', 'Чита',
  'Элиста', 'Южно-Сахалинск', 'Южноуральск', "Симферополь", "Севастополь", "Керчь",
  "Евпатория",
  "Ялта",
  "Феодосия",
  "Джанкой",
  "Алушта",
  "Бахчисарай",
  "Красноперекопск",
  "Саки",
  "Армянск",
  "Судак",
  "Белогорск",
  "Щёлкино",
  "Старый Крым",
  "Алупка",
  "Донецк",
  "Мариуполь",
  "Луганск",
  "Мелитополь"
];

export default cities;
