
const bank = [
  'Сбербанк',
  'ВТБ',
  'Газпромбанк',
  'Альфа-банк',
  'РСХБ(Россельхозбанк)',
  'Банк Открытие',
  'Совкомбанк ',
  'Росбанк',
  'Рйффайзенбанк',
  'Т-Банк',
  'Банк Россия',
  'Почта Банк',
  'Банк Уралсиб',
  'МТС Банк',
  'РНКБ',
  'УБРиР',
  'Ренессанс Банк',
  'Яндекс Банк',
  'Промсвязьбанк',
];

export default bank;
